import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_group_wrapper = _resolveComponent("form-group-wrapper")!

  return (_openBlock(), _createBlock(_component_form_group_wrapper, {
    labelValue: _ctx.labelValue,
    labelWrapperClassList: _ctx.labelWrapperClassList,
    labelClassList: _ctx.labelClassList
  }, {
    default: _withCtx(() => [
      _createElementVNode("input", {
        class: _normalizeClass(["form-control", _ctx.classes]),
        type: "checkbox",
        checked: _ctx.modelValue,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.checked)))
      }, null, 42, _hoisted_1)
    ]),
    _: 1
  }, 8, ["labelValue", "labelWrapperClassList", "labelClassList"]))
}
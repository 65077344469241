<template lang="pug">
.menu-item.px-5(:data-kt-menu-trigger='trigger' :data-kt-menu-placement='position')
  .menu-link.px-5()
    slot
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    trigger: {
      type: String,
      required: false,
      default: () => null
    },
    position: {
      type: String,
      required: false,
      default: () => null
    }
  }
})
</script>

import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: _normalizeClass(["form-control form-control-color", {hiddenTheme: _ctx.theme === 'hidden'}]),
    type: "color",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.colorRef) = $event)),
    title: "Choose your color",
    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changed && _ctx.changed(...args)))
  }, null, 34)), [
    [_vModelText, _ctx.colorRef]
  ])
}
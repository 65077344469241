import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_group_wrapper = _resolveComponent("form-group-wrapper")!

  return (_openBlock(), _createBlock(_component_form_group_wrapper, {
    labelValue: _ctx.labelValue,
    labelWrapperClassList: _ctx.labelWrapperClassList,
    labelClassList: _ctx.labelClassList
  }, {
    default: _withCtx(() => [
      _createElementVNode("select", {
        class: _normalizeClass(["form-control form-control-solid", _ctx.classes]),
        value: _ctx.modelValue,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option.value,
            key: option.name
          }, _toDisplayString(option.name), 9, _hoisted_2))
        }), 128))
      ], 42, _hoisted_1)
    ]),
    _: 1
  }, 8, ["labelValue", "labelWrapperClassList", "labelClassList"]))
}
export const copyToClipboard = {
    mounted(el, bindings) {
        el.classList.add('position-relative');
        // const tooltipWrapper = document.createElement('div');
        // tooltipWrapper.classList.add("tooltipWrapperCTC");
        // const tooltipInner = document.createElement('div');
        // tooltipInner.classList.add('tooltipInnerCTC');
        // tooltipInner.innerHTML = 'Copied';
        // tooltipWrapper.append(tooltipInner);
        
        // el.append(tooltipWrapper);

        el.addEventListener('click', async () => {
            try {
                // const basicDelayMS = 200;
                const txt = el.innerText.replace('Copied', '').trim();
                await navigator.clipboard.writeText(txt);
                // tooltipWrapper.style.display = 'flex';
                // setTimeout(() => {
                //     tooltipWrapper.style.opacity = '1';
                // }, basicDelayMS*1);
                // setTimeout(() => {
                //     tooltipWrapper.style.opacity = '0';
                // }, basicDelayMS*2)
                // setTimeout(() => {
                //     tooltipWrapper.style.display = 'none';
                // }, basicDelayMS*2.2)
            } catch (e) {
                //
            }
        });
    }
};

import { createApp } from "vue";
import App from "./App.vue";

//@ts-ignore
import router from './router.ts';
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";


document.title = "Z2A Publishers";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);

initInlineSvg(app);
initVeeValidate();


import Datepicker from 'vue3-date-time-picker';
import SelectElement from '@/components/UI/SelectElement.vue';
import InputElement from '@/components/UI/InputElement.vue';
import CheckboxElement from '@/components/UI/CheckboxElement.vue';
import 'vue3-date-time-picker/dist/main.css';
import vSelect from 'vue-select';
import {initBootstrap} from '@/core/components/bootstrap';
import DwLoader from '@/components/DwLoader.vue';
import Notifications from '@kyvg/vue3-notification';
import VueFinalModal  from 'vue-final-modal';
import Pagination from '@/components/Pagination.vue';
import ButtonExportReport from "@/components/exports/ButtonExportReport.vue";
import CardToolbarMenu from "@/components/templates/CardToolbarMenu.vue";
import CardToolbarMenuItem from "@/components/templates/CardToolbarMenuItem.vue";
import { copyToClipboard } from './components/common/directives/copy-to-clipboard';
import { doubleClickCallback } from './components/common/directives/double-click-callback';
import { clickOutside } from './components/common/directives/click-outside';


app.component('Pagination', Pagination);
app.component('Datepicker', Datepicker);
app.component('SelectElement', SelectElement);
app.component('InputElement', InputElement);
app.component('CheckboxElement', CheckboxElement);
app.component('v-select', vSelect);
app.directive('clipboard', copyToClipboard);
app.directive('double-click-callback', doubleClickCallback);
app.directive('click-outside', clickOutside);
app.component('DwLoader', DwLoader);
app.component("ButtonExportReport", ButtonExportReport);
app.component("CardToolbarMenu", CardToolbarMenu);
app.component("CardToolbarMenuItem", CardToolbarMenuItem);


app.use(VueFinalModal(), {
	key: '$modal',
	componentName: 'VueFinalModal',
	dynamicContainerName: 'ModalsContainer'
});

initBootstrap(app);

app.use(i18n);
app.use(Notifications);

app.mount("#app");
(function(){
	let el = document.createElement('link');
	el.rel = 'icon';
	el.href = '/media/logos/Z2A_Digital_Logomark_RGB_Old-style_Black.png';
	document.head.append(el);
})();


import {defineComponent, ref, watch} from 'vue';

export default defineComponent({
	name: 'bs-color-picker',
	props: {
		color: {
			type: String,
			default: () => '#563d7c',
			required: false
		},
		theme: {
			type: String as () => 'default' | 'hidden',
			default: () => 'default',
			required: false
		},
		callbackData: {
			default: () => null,
			required: false
		}
	},
	setup(props, {emit}) {
		const colorRef = ref(props.color);
		const defaultColor = ref(props.color);

		watch(colorRef, (v) => {
			emit('update:color', v);
		});
		return {colorRef, defaultColor}
	},
	methods: {
		changed() {
			this.$emit('updated', this.defaultColor, this.callbackData);
		}
	}
})

import ApiService from '@/core/services/ApiService';
import mime from "mime-types";
import { notify } from '@kyvg/vue3-notification';


const entityData = {

	advertiser: {
		async get(id: number) {
			const resp = await DataService.get(`advertisers/get/${id}`)
			return resp && resp.entity ? resp.entity : null;
		},
		async getOptions(network_id: null | number = null) {
			const resp = await DataService.get(`advertisers/getOptions`)
			return resp && resp.options ? resp.options : [];
		}
	},

	publisher: {
		async get(id: number) {
			const resp = await DataService.get(`publishers/get/${id}`)
			return resp && resp.entity ? resp.entity : null;
		},
		async getOptions(network_id: null | number = null) {
			const resp = await DataService.get(`publishers/getOptions`)
			return resp && resp.options ? resp.options : [];
		}
	},


	offer: {
		async getOptions(keyword: string | null = null, ids: number[] | null = []) {
			const resp = await DataService.get(`offers/getOptions`, {keyword: keyword, id: ids})
			return resp && resp.options ? resp.options : [];
		},
		async get(id: number) {
			const resp = await DataService.get(`offers/get/${id}`)
			return resp && resp.entity ? resp.entity : null;
		},
	},

	placement: {
		async get(id: number) {
			const resp = await DataService.get(`placements/get/${id}`)
			return resp && resp.entity ? resp.entity : null;
		},
		async getOptions(keyword: string | null = null, ids: number[] | null = []) {
			const resp = await DataService.get(`placements/getOptions`, {keyword: keyword, placement_id: ids})
			return resp && resp.options ? resp.options : [];
		}
	},

	asset: {
		async getList(query = '', ids: number[] = []) {
			const resp = await DataService.get(`assets/getList`, {keyword: query, id: ids});
			return resp && resp.records ? resp.records : [];
		},
		async getOptions(keyword: string | null = null, ids: number[] | null = []) {
			const resp = await DataService.get(`assets/getOptions`, {keyword: keyword, id: ids})
			return resp && resp.options ? resp.options : [];
		}
	},

	country: {
		_cache: null,
		async getOptions(modifiable = false) {
		  if (!this._cache || modifiable) {
			const resp = await DataService.get(`utils/geo/getCountries`);
			if (resp.options) {
			  if (modifiable) {
				return resp.options || [];
			  }
			  this._cache = resp.options;
			}
		  }
		  return this._cache || [];
		},
	},

	city: {
		_cache: {},
		async getRegionOptions(regionId: string) {
			if (!this._cache[regionId]) {
			const resp = await DataService.get(`utils/geo/getCities/` + regionId);
			if (resp.options) {
				this._cache[regionId] = resp.options;
			}
			}
			return this._cache[regionId] || [];
		},
	},

	region: {
		_cache: {},
		async getCountryOptions(country: string) {
			if (!this._cache[country]) {
			const resp = await DataService.get(`utils/geo/getRegions/` + country);
			if (resp.options) {
				this._cache[country] = resp.options;
			}
			}
			return this._cache[country] || [];
		},
	},
}

class DataService {
	// SHOULD BE ONLY USED FOR TESTING OR DEVELOPMNET PURPOSES !!!!
	static async adminGet(path: string, params?: any) {
		try {
			const resp = await ApiService.get(path, {params});
			if(resp.status === 500) {
				notify({title: "Whoops!", text: resp.data.message || 'Something went wrong', type: 'error'});
				return;
			}
			return resp.data.data;
		} catch (e: any) {
			console.error('err:', e);
		}
		return null;
	}
	static async get(path: string, params?: any) {
		try {
			const resp = await ApiService.get('/partners/' + path, {params});
			if(resp.status === 500) {
				notify({title: "Whoops!", text: resp.data.message || 'Something went wrong', type: 'error'});
				return;
			}
			return resp.data.data;
		} catch (e: any) {
			console.error('err:', e);
		}
		return null;
	}

	static async downloadGet(path: string, filename = 'export', params?: any) {
		try {
			const response = await ApiService.get(path, {params});
			const ext = mime.extension(response.headers['content-type']);
			const blob = new Blob([response.data]);
			let link = document.createElement('a');
			link.download = `${filename}.${ext}`;
			link.href = URL.createObjectURL(blob);
			link.click();
			URL.revokeObjectURL(link.href);
		} catch (e) {
			console.error(e);
		}
		return null;	
	}

	static async downloadPost(path: string, filename = 'export', params?: any) {
		try {
			const response = await ApiService.post(path, params);
			const ext = mime.extension(response.headers['content-type']);
			const blob = new Blob([response.data]);
			let link = document.createElement('a');
			link.download = `${filename}.${ext}`;
			link.href = URL.createObjectURL(blob);
			link.click();
			URL.revokeObjectURL(link.href);
		} catch (e) {
			console.error(e);
		}
		return null;	
	}

	static async post(path: string, params?: any) {
		try {
			const resp = await ApiService.post(path, params);
			if(resp.status === 500) {
				notify({title: "Whoops!", text: resp.data.message || 'Something went wrong', type: 'error'});
				return;
			}
			return resp.data.data;
		} catch (e: any) {
			notify({title: "Whoops!", text: e.response.data.message || 'Something went wrong', type: 'error'});
			console.error(e);
		}
		return null;
	}

	public static entity = entityData;

	public static options = {
		mmp: [
			{v: 1, t: 'AppsFlyer'},
			{v: 2, t: 'Adjust'},
			{v: 3, t: 'Branch'},
			{v: 4, t: 'Kochava'},
			{v: 5, t: 'Singular'},
			{v: 6, t: 'Other'},
		],
		attribution_types: [{v: '', t: 'All'}, {v: 0, t: 'Click'}, {v: 1, t: 'Impression'}],
		offer_status: [
			{v: '', t: 'All'},
			{v: 'draft', t: 'Draft'},
			{v: 'live', t: 'Live'},
			{v: 'paused', t: 'Paused'},
			{v: 'canceled', t: 'Canceled'},
			{v: 'ended', t: 'Ended'},
		],
		payout_model: [
			{v: '', t: 'All'},
			{v: 'CPA', t: 'CPA'},
			{v: 'CPI', t: 'CPI'},
			{v: 'CPC', t: 'CPC'},
			{v: 'CPM', t: 'CPM'},
		],
		placement_status: [
			{v: '', t: 'All'},
			{v: 'draft', t: 'Draft'},
			{v: 'live', t: 'Live'},
			{v: 'paused', t: 'Paused'},
			{v: 'canceled', t: 'Canceled'},
		],
		platform: [
			{v: '', t: 'All'}, 
			{v: 'ios', t: 'ios'}, 
			{v: 'android', t: 'android'}, 
			{v: 'web', t: 'web'}
		],
		platform_related_to_id: [
			{ v: "", t: "All" },
			{ v: 1, t: "ios" },
			{ v: 2, t: "android" },
		],
		os: [
			{ v: "", t: "All" },
			{ v: 1, t: "ios" },
			{ v: 2, t: "android" },
		],
		converted_types: [
			{ v: null, t: "All" },
			{ v: "converted", t: "Converted Only" },
			{ v: "not_converted", t: "Not Converted" },
		],
	};

	static async getIntegrationTypes(){
		let resp = await this.get('external-offers/getIntegrationTypes');
		return resp.integration_types;
	}

}

export default DataService;
export const clickOutside = {
    mounted(el, binding) {
        el.clickOutside = function(e: MouseEvent) {
        const targetElement = binding.value.target ? document.querySelector(binding.value.target) : el;
        const target = e.target as any;

        try {
            const itemQuery = `${binding.value.target} ${target.tagName}.${Array.from(target.classList).join('.')}`;
            if(document.querySelector(itemQuery)) return;
        } catch(e) { /** */}



        if(!targetElement) return;
        if(
            targetElement !== e.target &&
            !targetElement.contains(e.target) &&
            el !== e.target &&
            !el.contains(e.target)
        ) {
            binding.value.clb(e.target);
        }
        }
        document.body.addEventListener('click', el.clickOutside)
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutside);
    }
};

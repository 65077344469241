
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Pagination',
	props: {
		modelValue: {
			default: {
				page: 1,
				page_size: 30
			},
			required: true,
		},
	},
	computed: {
		pagination: {
			get(){
				return this.modelValue;
			},
			set(v){
				this.$emit('update:modelValue', v);
			}
		}
	},
	methods: {
		loadData(){
			this.$emit("change");
		}
	}
});

<template>
	<slot/>
</template>
<script>
import {defineComponent} from "vue";
import {Popover} from 'bootstrap';
export default defineComponent({
	name: 'bs-popover',
	props: {
		content: {
			required: false,
			default: '',
		},
		title: {
			default: 'My Popover',
		},
		trigger: {
			default: 'click',
		},
		delay: {
			default: 0,
		},
		html: {
			default: false,
		},
		template: {
			required: false,
			default: ''
		}
	},
	mounted() {
		let options = this.$props;
		const tmp = Object.assign({}, options);
		if(!tmp.template) {
			delete tmp.template;
		}
		let elements = this.$slots.default();
		let ele = elements[0];
		new Popover(ele.el, tmp);
	}
});
</script>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pagination-wrapper" }
const _hoisted_2 = { class: "pagination-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_pagination, {
        "current-page": _ctx.pagination.page,
        "onUpdate:current-page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.page) = $event)),
        "current-pageModifiers": { sync: true },
        onCurrentChange: _ctx.loadData,
        "page-sizes": [10, 20, 30, 50, 100, 200, 500],
        "page-size": _ctx.pagination.page_size,
        "onUpdate:page-size": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination.page_size) = $event)),
        layout: "total, prev, pager, next, sizes",
        total: _ctx.pagination.total,
        "hide-on-single-page": false,
        background: "",
        onSizeChange: _ctx.loadData
      }, null, 8, ["current-page", "onCurrentChange", "page-size", "total", "onSizeChange"])
    ])
  ]))
}

import { defineComponent } from 'vue'
import FormGroupWrapper from './FormGroupWrapper.vue';

export default defineComponent({
    components: {FormGroupWrapper},
    props: {
        type: {
            default: 'text',
            type: String
        },
        modelValue: {},
        placeholder: {
            default: '',
            type: String
        },
        classListInput: {
            type: Array,
            default: () => [],
            required: false
        },
        labelValue: {
            type: String,
            default: "",
            required: false
        },
        labelWrapperClassList: {
            type: Array,
            default: () => [],
            required: false
        },
        labelClassList: {
            type: Array,
            default: () => [],
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        wrapperMargins: {
            type: Boolean,
            default: () => true,
            required: false
        }
    },
    computed: {
        classes() {
            return this.classListInput.join(" ")
        }
    }
})

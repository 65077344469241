import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {Actions, Mutations} from '@/store/enums/StoreEnums';

interface PageInfo {
	backButton: any;
	pageButtons: any[];
}

interface StoreInfo {
 	pageInfo: PageInfo;
}

@Module
export default class PageModule extends VuexModule implements StoreInfo {
	pageInfo = {} as PageInfo;

	get getPageInfo(): PageInfo {
		return this.pageInfo;
	}

	get getPageButtons (): any[] {
		return this.pageInfo.pageButtons;
	}
	get getBackButton() {
		return this.pageInfo.backButton;
	}

	@Mutation
	[Mutations.SET_PAGE_INFO_MUTATION](payload){
		this.pageInfo = payload;
	}

	@Action
	[Actions.SET_PAGE_INFO_ACTION](payload){
		this.context.commit(Mutations.SET_PAGE_INFO_MUTATION, payload);
	}

	@Mutation
	[Mutations.SET_PAGE_BUTTONS_MUTATION](payload){
		// this.pageInfo = payload;
		this.pageInfo.pageButtons = payload;
	}

	@Action
	[Actions.SET_PAGE_BUTTONS](payload){
		this.context.commit(Mutations.SET_PAGE_BUTTONS_MUTATION, payload);
	}
}
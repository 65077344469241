import {Popover} from 'bootstrap';

export const bsPopoverDirective = {
	mounted(el, bindings) {
        const data = {
            content: "Some test content",
            title: "Some title",
            trigger: "click",
            delay: 0,
            html: false
        };


		const options = bindings.value || {};
        Object.keys(options).forEach((key) => {
            const value = options[key];
            data[key] = value;
        })
		new Popover(el, data);
	}
};
import {createStore} from "vuex";
import {config} from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import PageModule from "@/store/modules/PageModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
	state() {
		return {};
	},
	modules: {
		AuthModule,
		BodyModule,
		BreadcrumbsModule,
		ConfigModule,
		PageModule,
	},
});

export default store;

<template lang="pug">
.form-group(style="display: inline-block" :class="getMargins")
    .d-flex.position-relative(:class="getWrapperClasses")
        .form-group.relative
            label.form-label(v-if="labelValue" :class="getLabelClasses") {{ labelValue }} 
        slot
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
   props: {
       labelValue: {
           type: String,
           default: "",
           required: false
       },
       labelWrapperClassList: {
           type: Array,
           default: () => [],
           required: false
       },
       labelClassList: {
           type: Array,
           default: () => [],
           required: false
       },
        wrapperMargins: {
            type: Boolean,
            default: () => true,
            required: false
        }
   },
   computed: {
       getWrapperClasses() {
           return this.labelWrapperClassList.join(" ");
       },
       getLabelClasses() {
           return this.labelClassList.join(" ");
       },
       getMargins() {
           return this.wrapperMargins ? 'm-2' : 'm-0'
       }
       
   }
})
</script>

import {App} from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/JwtService';
import {AxiosResponse, AxiosRequestConfig} from 'axios';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
	/**
	 * @description property to share vue instance
	 */
	public static vueInstance: App;

	/**
	 * @description initialize vue axios
	 */
	public static init(app: App<Element>) {
		ApiService.vueInstance = app;
		ApiService.vueInstance.use(VueAxios, axios);
		ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
	}

	/**
	 * @description set the default HTTP request headers
	 */
	public static setHeader(): void {
		const token = JwtService.getToken();
		ApiService.vueInstance.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		ApiService.vueInstance.axios.defaults.headers.common['Accept'] = 'application/json';
	}

	/**
	 * @description send the GET HTTP request
	 * @param resource: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static query(
		resource: string,
		params: AxiosRequestConfig
	): Promise<AxiosResponse> {
		if (resource.charAt(0) !== '/') {
			resource = 'partners/' + resource;
		}
		return ApiService.vueInstance.axios.get(resource, params);
	}

	/**
	 * @description send the GET HTTP request
	 * @param resource: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static get(
		resource: string,
		params: AxiosRequestConfig
	): Promise<AxiosResponse> {
		if (resource.charAt(0) !== '/') {
			resource = 'admin/' + resource;
		}
		params.validateStatus = (status: number): boolean => {
			return status >= 200 && status < 400 || status == 500;
		};
		return ApiService.vueInstance.axios.get(resource, params);
		//   resource: string,
		//   slug = "" as string
		// ): Promise<AxiosResponse> {
		//   return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
		// }
	}

	/**
	 * @description set the POST HTTP request
	 * @param resource: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static post(
		resource: string,
		params: AxiosRequestConfig
	): Promise<AxiosResponse> {
		if (resource.charAt(0) !== '/') {
			resource = 'partners/' + resource;
		}
		params.validateStatus = function (status: number): boolean {
			return status >= 200 && status < 400 || status == 500;
		};
		return ApiService.vueInstance.axios.post(`${resource}`, params);
	}

	/**
	 * @description send the UPDATE HTTP request
	 * @param resource: string
	 * @param slug: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static update(
		resource: string,
		slug: string,
		params: AxiosRequestConfig
	): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
	}

	/**
	 * @description Send the PUT HTTP request
	 * @param resource: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static put(
		resource: string,
		params: AxiosRequestConfig
	): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.put(`${resource}`, params);
	}

	/**
	 * @description Send the DELETE HTTP request
	 * @param resource: string
	 * @returns Promise<AxiosResponse>
	 */
	public static delete(resource: string): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.delete(resource);
	}
}

export default ApiService;

import {App} from 'vue';
import bsPopover from './Popover.vue';
import {bsTooltip} from '@/core/components/tooltip';
import bsDropdown from './Dropdown.vue';
import bsColorPicker from './ColorPicker.vue';
import { bsPopoverDirective } from './popover';

export async function initBootstrap(app: App<Element>){
	app.component('bs-popover', bsPopover);
	app.component('bs-dropdown', bsDropdown);
	app.component('bs-color-picker', bsColorPicker);
	app.directive('bs-tooltip', bsTooltip);
	app.directive('bs-popover', bsPopoverDirective);
}

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47f58eaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown", _ctx.classList])
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(["btn btn-secondary dropdown-toggle", _ctx.buttonClasses]),
      type: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false"
    }, _toDisplayString(_ctx.name), 3),
    _createElementVNode("ul", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}
<template lang="pug">
button.btn.btn-light-primary.me-3(type="button" @click="exportReport" :class="{'no-btn-styling': noButtonStyling, 'no-global-styling': noStyling}")
    span.svg-icon.svg-icon-2.d-flex.flex-row.align-items-center(style="white-space:nowrap;")
        inline-svg.me-1(src="media/icons/duotune/arrows/arr078.svg")
        | Export
    dw-loader(:active.sync="busy")
</template>
<script>
import DataService from '@/core/services/DataService';
import { defineComponent } from 'vue';
import DwLoader from '@/components/DwLoader.vue';

export default defineComponent({
   components: {DwLoader},
   props: {
       entity_endpoint_name: {
           type: String,
           default: () => null,
           required: true
       },
       export_type: {
           type: String,
           default: () => 'csv',
           required: false
       },
       filename: {
           type: String,
           default: () => 'exports',
           required: false
       },
       noButtonStyling: {
           type: Boolean,
           required: false,
           default: () => false
       },
       noStyling: {
           type: Boolean,
           required: false,
           default: () => false
       },
       payload: {
           required: false,
           default: () => ({})
       },
       requestMethod: {
           type: String,
           required: false,
           default: "GET"
       }
   },
   data() {
       return {busy: false}
   },
   methods: {
       async exportReport() {
			try {
				this.busy = true;
                console.log(this.requestMethod);
                if(this.requestMethod === "GET") {
                    await DataService.downloadGet(`${this.entity_endpoint_name}`, this.filename, this.payload);
                } else {
                    await DataService.downloadPost(`${this.entity_endpoint_name}`, this.filename, this.payload);
                }
			} catch(e) {
                console.log("ERR:", e);
				//
			} finally {
                this.busy = false;
            }
       }
   }

})
</script>
<style lang="scss">
button.no-btn-styling.btn.btn-light-primary {
    border: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    color: #009EF7 !important;
    svg {
        path,rect {
            fill: #009EF7 !important;
        }
    }
    &:hover {    
        background: transparent !important;
        color: #009EF7 !important;
        svg {
            fill: #009EF7 !important;
            path, rect {
                fill: #009EF7 !important;
            }
        }
    }
    &.no-global-styling {
        color: inherit !important;
        font-size: inherit;
        font-weight: inherit;
        text-transform: inherit;
        svg {display: none}

    }
}
</style>
import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
import store from '@/store';
import {Mutations, Actions} from '@/store/enums/StoreEnums';

const withPrefix = (prefix, routes) =>
	routes.map((route) => {
		route.path = prefix + route.path;
		return route;
	});

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/offers',
		component: () => import('@/views/layout/Layout.vue'),
		children: [
			{
				path: '/dashboard',
				name: 'dashboard',
				component: () => import('@/views/Dashboard.vue'),
			},

			{
				path: '/reports',
				name: 'reports',
				component: () => import('@/views/reports/Reports.vue'),
			},
			{
				path: '/conversions',
				name: 'conversions',
				component: () => import('@/views/reports/Conversions.vue'),
			},
			{
				path: '/docs/get_offers',
				name: 'docs-campaigns',
				component: () => import('@/views/docs/GetOffers.vue')
			},
			{
				path: '/docs/traffic',
				name: 'docs-traffic',
				component: () => import('@/views/docs/Traffic.vue')
			},

			...withPrefix('offers', [
				{
					name: 'offer-list', path: '',
					component: () => import('@/views/offers/List.vue')
				},
				{
					name: 'offer-view', path: '/view/:id',
					component: () => import('@/views/offers/View.vue')
				},
			]),
		],
	},
	{
		path: '/',
		component: () => import('@/components/page-layouts/Auth.vue'),
		children: [
			{
				path: '/sign-in',
				name: 'sign-in',
				component: () =>
					import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
			},
			{
				path: '/sign-up',
				name: 'sign-up',
				component: () =>
					import('@/views/crafted/authentication/basic-flow/SignUp.vue'),
			},
			// {
			// 	path: '/password-reset',
			// 	name: 'password-reset',
			// 	component: () =>
			// 		import('@/views/crafted/authentication/basic-flow/PasswordReset.vue'),
			// },
		],
	},
	{
		// the 404 route, when none of the above matches
		path: '/404',
		name: '404',
		component: () => import('@/views/crafted/authentication/Error404.vue'),
	},
	{
		path: '/500',
		name: '500',
		component: () => import('@/views/crafted/authentication/Error500.vue'),
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/404',
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach(() => {
	// reset config to initial state
	store.commit(Mutations.RESET_LAYOUT_CONFIG);

	store.dispatch(Actions.VERIFY_AUTH);

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

export default router;



import { defineComponent } from 'vue'
import FormGroupWrapper from './FormGroupWrapper.vue';
import {SelectElementOption} from './interfaces';

export default defineComponent({
    components: {FormGroupWrapper},
    props: {
        options: {
            type: Array,
            default: (): SelectElementOption[] => [],
            required: true
        },
        modelValue: {
            type: String,
            default: ""
        },
        labelValue: {
            type: String,
            default: "",
            required: false
        },
        classListInput: {
            type: Array,
            default: () => [],
            required: false
        },
        labelWrapperClassList: {
            type: Array,
            default: () => [],
            required: false
        },
        labelClassList: {
            type: Array,
            default: () => [],
            required: false
        }
    },
    computed: {
        classes() {
            return this.classListInput.join(" ")
        }
    }
})



import {defineComponent} from 'vue';

export default defineComponent({
	name: 'bs-dropdown',
	data() {
		return {
			stylingClassList: {
				min: 'min-styles',
				default: []
			}
		}
	},
	props: {
		name: {
			required: false,
			default: () => '',
			type: String
		},
		styling: {
			required: false,
			default: () => 'default',
			type: String as () => 'default' | 'min'
		},
		buttonClasses: {
			required: false,
			default: () => [],
			type: Array
		}
	},
	computed: {
		classList() {
			return this.stylingClassList[this.styling]
		}
	}
})

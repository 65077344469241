import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group relative d-flex flex-row align-items-center" }
const _hoisted_2 = ["type", "placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_group_wrapper = _resolveComponent("form-group-wrapper")!

  return (_openBlock(), _createBlock(_component_form_group_wrapper, {
    labelValue: _ctx.labelValue,
    labelWrapperClassList: _ctx.labelWrapperClassList,
    labelClassList: _ctx.labelClassList,
    wrapperMargins: _ctx.wrapperMargins
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default"),
        _createElementVNode("input", {
          class: _normalizeClass(["form-control form-control-solid", _ctx.classes]),
          type: _ctx.type,
          placeholder: _ctx.placeholder,
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          disabled: _ctx.disabled ? _ctx.disabled : false
        }, null, 42, _hoisted_2)
      ])
    ]),
    _: 3
  }, 8, ["labelValue", "labelWrapperClassList", "labelClassList", "wrapperMargins"]))
}
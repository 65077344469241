export const doubleClickCallback = {
    mounted(el, bindings) {

        el.addEventListener('dblclick', async () => {
            try {
                bindings.value.callback();
            } catch (e) {
                //
            }
        });
    }
};